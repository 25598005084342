import LogoImage from "../../img/logo/aiapi_logo.svg";
import "./footer.css";

const FooterComponent = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="footer__logo">
          <img className="footer__logo-img" src={LogoImage} alt="Company logo" />
        </div>
        <div className="footer__text">
          <div className="footer__text-name">
            © 2025 Международная транспортно-логистическая компания AIAPI TRANS
          </div>
          <div className="footer__text-made">
            <a
              href="https://github.com/AlekseiHead"
              target="_blank"
              rel="noreferrer"
            >
              Powered by head.dev
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
